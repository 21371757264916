@media screen and (width <= 800px) {
  .search-panel__filters {
    display: none;
  }
}

.header {
  color: #a75072;
  background-image: linear-gradient(284deg, #0066b2, #5072a7);
  align-items: center;
  min-height: 50px;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  display: flex;
}

.header a {
  color: #fff;
  text-decoration: none;
}

.header-title {
  font-size: 1.2rem;
  font-weight: normal;
}

.header-subtitle {
  font-size: 1.2rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
}

.link-list a {
  color: #a75072;
  text-decoration: none;
}

.search-panel {
  display: flex;
}

.search-panel__filters {
  flex: 1;
}

.search-panel__filters a {
  color: #a75072;
  text-decoration: none;
}

.ais-RefinementList-labelText {
  color: #a75072;
}

.ais-RefinementList-majoText {
  color: #5072a7;
}

.search-panel__results {
  flex: 3;
}

.ais-Highlight-highlighted {
  color: inherit;
  font-size: inherit;
}

#searchbox {
  margin-bottom: 2rem;
}

#pagination {
  text-align: center;
  margin: 2rem auto;
}

article h1 {
  color: #a75072;
}

.ccam-code {
  cursor: pointer;
}

.menu-container {
  color: gray;
  margin-bottom: 2em;
  margin-left: 2em;
  font-size: small;
}

.menu-container span {
  margin-top: 0;
  margin-bottom: 0;
}

.menu-container a {
  color: #72a750;
  text-decoration: none;
}

h1 a {
  color: #a75072;
  text-decoration: none;
}

#footer {
  text-align: center;
  margin-top: 2em;
}

#footer span {
  padding-right: 2em;
}

#footer a {
  color: #a75072;
  text-decoration: none;
}

#copy-ok-notif {
  text-align: center;
  color: #fff;
  background-color: #72a750;
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 20px;
  display: none;
}

#med-sms {
  margin-top: 1em;
}

/*# sourceMappingURL=index.a6554a1a.css.map */
