@media screen and (max-width: 800px) {
    .search-panel__filters {
        display: none;
    }
}

.header {
    display: flex;
    align-items: center;
    min-height: 50px;
    padding: 0.5rem 1rem;
    background-image: linear-gradient(284deg, #0066b2, #5072A7);
    color: #a75072;
    margin-bottom: 1rem;
}

.header a {
    color: #fff;
    text-decoration: none;
}

.header-title {
    font-size: 1.2rem;
    font-weight: normal;
}

.header-subtitle {
    font-size: 1.2rem;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
}

.link-list a {
    text-decoration: none;
    color: #a75072;
}

.search-panel {
    display: flex;
}

.search-panel__filters {
    flex: 1;
}

.search-panel__filters a {
    text-decoration: none;
    color: #a75072;
}

.ais-RefinementList-labelText {
    color: #a75072;
}

.ais-RefinementList-majoText {
    color: #5072A7;
}

.search-panel__results {
    flex: 3;
}

.ais-Highlight-highlighted {
    color: inherit;
    font-size: inherit;
}

#searchbox {
    margin-bottom: 2rem;
}

#pagination {
    margin: 2rem auto;
    text-align: center;
}

article h1 {
    color: #a75072;
}

.ccam-code {
    cursor: pointer;
}

.menu-container {
    margin-bottom: 2em;
    margin-left: 2em;
    font-size: small;
    color: grey;
}

.menu-container span {
    margin-bottom: 0;
    margin-top: 0;
}

.menu-container a {
    text-decoration: none;
    color: #72a750;
}

h1 a {
    text-decoration: none;
    color: #a75072;
}

#footer {
    text-align: center;
    margin-top: 2em;
}

#footer span {
    padding-right: 2em;
}

#footer a {
    color: #a75072;
    text-decoration: none;
}

#copy-ok-notif {
    text-align: center;
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: 20px;
    color: white;
    background-color:#72a750;
    display: none;
}

#med-sms {
    margin-top: 1em;
}